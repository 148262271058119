<div *ngIf="!isDisabled">
    <div *ngIf="isOrderFetched">
        <app-message 
        [confirmMessage]="confirmMessage" 
        [showPOSMessage]="showPOSMessage"
        [validationMessage]="validationMessage"></app-message>
    </div>
    
    
    <!-- Loading -->
    <div *ngIf="!isOrderFetched">
        <div class="shadowLoading"></div>
        <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
        </div>
    </div>
</div>

<div id="divStatic" *ngIf="isDisabled && isOrderFetched">
    <div class="row">
        <div class="col-xs-12 
        col-sm-8 col-sm-offset-2
        col-md-6 col-md-offset-3
        col-lg-6 col-lg-offset-3 
        col-xl-4 col-xl-offset-4">
        <div class="wrapper">
            <div class="row">
                <div class="col-xs-12 text-center" style="margin-bottom: 0em;">
                    <img src="../../assets/img/logo_promart.png" 
                    alt=""
                    class="img-responsive"
                    style="max-height: 127px;">
                </div>
                <div class="col-xs-12 text-center" 
                style="margin-bottom: 1.5em;">
                    <h3 style="margin-top:0px;">Esta opción ya no se <br/> encuentra habilitada</h3>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>