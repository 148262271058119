import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UbigeoService } from './ubigeo.service';
import { District } from './district.modal';
import { BackendService } from 'src/app/shared/backend.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  @ViewChild('f', { static: false }) slForm: NgForm;
  @Input() orderId: string;
  districts: District[];
  isLoading: boolean = false;
  isOrderSent: boolean = false;

  constructor(private ubigeoService: UbigeoService,
    private backendService: BackendService) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.ubigeoService.fetchDistrictsByProvince(environment.ubigeoApiLimaProvinceCode)
      .subscribe(districts => {
        this.districts = districts;
        this.isLoading = false;
      }, error => {
        console.log("api ubigeo error:", error);
        this.isLoading = false;
      });
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;

    const data = {
      "orderId": this.orderId,
      "tipo": "1",
      "distrito": this.slForm.value.district,
      "direccion": this.slForm.value.address,
      "referencia": this.slForm.value.reference

    }

    console.log("Sending data");
    console.log(data);

    this.backendService.sendData(data)
      .subscribe(resp => {
        this.isLoading = false;
        this.isOrderSent = true;
        this.slForm.reset();
        this.backendService.dataSent.next(true);
      }, error => {
        this.isLoading = false;
        this.isOrderSent = false;
        console.log("api backend error send:", error);
      });
  }

}
