<div class="wrapper">
    <div class="row">
        <div class="col-xs-12 text-center" style="margin-bottom: 0em;">
            <img src="../../assets/img/logo_promart.png" 
            alt=""
            class="img-responsive"
            style="max-height: 127px;">
        </div>
        <div *ngIf="confirmMessage" class="col-xs-12 text-center" 
        style="margin-bottom: 1.5em;">
            <h3 style="margin-top:0px;">Gracias por tu confirmación</h3>
        </div>
        <div *ngIf="confirmMessage" class="col-xs-12">
            <p style="max-width: 490px;margin: 0 auto">
                {{confirmMessage}}
            </p>
            <ng-content></ng-content>
            <div *ngIf="showPOSMessage" style="margin-top:30px;" class="row">
                <div class="col-xs-12">
                    <div class="row" style="max-width: 650px;margin: 0 auto;">
                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12" style="text-align: center;">
                            <img src="../../assets/img/icon-commerce-2.png" alt="" style="vertical-align: middle;max-width: 40px;">
                        </div>
                        <div class="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-xs-12 infoMessage text-center">
                            El personal de despacho llevará el POS para que realices el
                            pago en tu domicilio con cualquiera de las siguientes tarjetas:
                        </div>
                    </div>
                    <div class="row imgMobile" style="margin-top:30px;">
                        <div class="col-xs-12">
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-xs-6 text-right">
                                    <img src="../../assets/img/icon-visa.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </div>
                                <div class="col-xs-6 text-left">
                                    <img src="../../assets/img/icon-mastercard.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-4 text-center">
                                    <img src="../../assets/img/icon-american-express.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </div>
                                <div class="col-xs-4 text-center">
                                    <img src="../../assets/img/icon-diners-club.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </div>
                                <div class="col-xs-4 text-center">
                                    <img src="../../assets/img/icon-oh.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row imgDesktop" style="margin:0 auto;margin-top:30px;max-width: 490px;">
                        <div class="col-xs-2 col-xs-offset-1 text-center">
                            <img src="../../assets/img/icon-visa.png" alt="" style="vertical-align: middle;max-width: 50px;">
                        </div>
                        <div class="col-xs-2 text-center">
                            <img src="../../assets/img/icon-mastercard.png" alt="" style="vertical-align: middle;max-width: 50px;">
                        </div>
                        <div class="col-xs-2 text-center">
                            <img src="../../assets/img/icon-american-express.png" alt="" style="vertical-align: middle;max-width: 50px;">
                        </div>
                        <div class="col-xs-2 text-center">
                            <img src="../../assets/img/icon-diners-club.png" alt="" style="vertical-align: middle;max-width: 50px;">
                        </div>
                        <div class="col-xs-2 text-center">
                            <img src="../../assets/img/icon-oh.png" alt="" style="vertical-align: middle;max-width: 50px;">
                        </div>
                    </div>
                </div>
            </div>

            <!-- <table *ngIf="showPOSMessage" align="center" cellspacing="0" cellpadding="0" border="0" width="100%" style="max-width: 610px;margin-top:30px;">
                <tr>
                    <td valign="middle" width="70px;" style="text-align: center;">
                        <img src="../../assets/img/icon-commerce-2.png" alt="" style="vertical-align: middle;max-width: 40px;">
                    </td>
                    <td valign="middle" style="line-height: 24px;vertical-align: middle;font-size: 18px;">
                        El personal de despacho llevará el POS para que realices el
                        pago en tu domicilio con cualquiera de las siguientes tarjetas:
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="padding-top: 30px;">
                        <table align="center" cellspacing="0" cellpadding="0" border="0" width="100%" style="max-width: 330px;">
                            <tr>
                                <td valign="middle" style="text-align: center;">
                                    <img src="../../assets/img/icon-visa.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </td>
                                <td valign="middle" style="text-align: center;">
                                    <img src="../../assets/img/icon-mastercard.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </td>
                                <td valign="middle" style="text-align: center;">
                                    <img src="../../assets/img/icon-american-express.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </td>
                                <td valign="middle" style="text-align: center;">
                                    <img src="../../assets/img/icon-diners-club.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </td>
                                <td valign="middle" style="text-align: center;">
                                    <img src="../../assets/img/icon-oh.png" alt="" style="vertical-align: middle;max-width: 50px;">
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table> -->
        </div>
        <div *ngIf="validationMessage" class="col-xs-12 text-center">
            <h3 style="font-weight: bold;">
                {{validationMessage}}
            </h3>
        </div>
    </div>
</div>