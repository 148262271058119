import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MessageComponent } from './message/message.component';
import { AppRoutingModule } from './app-routing.module';
import { DeliveryComponent } from './delivery/delivery.component';
import { PickupComponent } from './pickup/pickup.component';
import { CancelComponent } from './cancel/cancel.component';
import { FormComponent } from './delivery/form/form.component';
import { HttpClientModule } from '@angular/common/http';
import { UbigeoService } from './delivery/form/ubigeo.service';
import { BackendService } from './shared/backend.service';
import { CanceltwoComponent } from './canceltwo/canceltwo.component';
import { RefundComponent } from './refund/refund.component';
import { CouponComponent } from './coupon/coupon.component';
import { DeliverytwoComponent } from './deliverytwo/deliverytwo.component';
import { StoreService } from './shared/store.service';
import { SharedModule } from './shared/shared.module';
import { IncompletePickingService } from './shared/incomplete-picking.service';
import { RescheduledByPickingService } from './shared/rescheduled-by-picking.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MessageComponent,
    DeliveryComponent,
    DeliverytwoComponent,
    PickupComponent,
    CancelComponent,
    RefundComponent,
    CouponComponent,
    FormComponent,
    CanceltwoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,

  ],
  providers: [UbigeoService, BackendService, StoreService, IncompletePickingService, RescheduledByPickingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
