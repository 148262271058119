import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  @Input() confirmMessage: string;
  @Input() showPOSMessage: boolean;
  @Input() validationMessage: string;

  constructor() { }

  ngOnInit(): void {
  }

}
