<div *ngIf="isOrderFetched && showDiv === 'form'">
    <app-form
    [orderId]="orderId"></app-form>
</div>
<div *ngIf="isOrderFetched && showDiv === 'alert'">
    <app-message 
    [confirmMessage]="confirmMessage" 
    [showPOSMessage]="showPOSMessage"
    [validationMessage]="validationMessage"></app-message>
</div>

<!-- Loading -->
<div *ngIf="!isOrderFetched">
    <div class="shadowLoading"></div>
    <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>