import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from './home/home.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { PickupComponent } from './pickup/pickup.component';
import { CancelComponent } from './cancel/cancel.component';
import { CanceltwoComponent } from './canceltwo/canceltwo.component';
import { CouponComponent } from './coupon/coupon.component';
import { RefundComponent } from './refund/refund.component';
import { DeliverytwoComponent } from './deliverytwo/deliverytwo.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'delivery', component: DeliveryComponent },
    { path: 'delivered', component: DeliverytwoComponent },
    { path: 'pickup', component: PickupComponent },
    { path: 'cancel', component: CancelComponent },
    { path: 'refund', component: RefundComponent },
    { path: 'coupon', component: CouponComponent },
    { path: 'canceled', component: CanceltwoComponent },
    { path: 'indecopi', loadChildren: () => import('./process-indecopi/process-indecopi.module').then(m => m.ProcessIndecopiModule) },
    { path: 'deposit/reminder', loadChildren: () => import('./deposit-reminder/deposit-reminder.module').then(m => m.DepositReminderModule) },
    { path: 'payment/legalinterests', loadChildren: () => import('./payment-interests-legal/payment-interests-legal.module').then(m => m.PaymentInterestsLegalModule) },
    { path: 'incompletepicking', loadChildren: () => import('./incomplete-picking/incomplete-picking.module').then(m => m.IncompletePickingModule) },
    { path: 'rescheduledbypicking', loadChildren: () => import('./rescheduled-by-picking/rescheduled-by-picking.module').then(m => m.RescheduledByPickingModule) }
]

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}