import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BackendService } from '../shared/backend.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  confirmMessage: string;
  showPOSMessage: boolean;
  validationMessage: string;
  token: string;
  isOrderFetched: boolean = false;
  showDiv: string;
  orderId: string;

  constructor(private route: ActivatedRoute,
    private backendService: BackendService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe((params: Params) => {
        this.token = params['token'];
        this.getOrder();
      });

      this.subscription = this.backendService.dataSent
      .subscribe(value => {
        if(value){
          this.confirmMessage = `Te enviaremos un correo electrónico y/o SMS para informarte la 
          fecha de entrega de tu pedido.`;
          this.showPOSMessage = true;
          this.validationMessage = "";
          this.showDiv = "alert";
        }
      });
  }

  getOrder() {
    this.backendService.getOrderByToken(this.token)
      .subscribe(order => {
        if (!order) {
          this.showAlert(`No se pudo obtener el pedido.`);
          return;
        }

        if (order.tipo !== 0) {
          this.showAlert(`Tu confirmación fue enviada anteriormente.`);
          return;
        }

        this.orderId = order.orderId;
        this.isOrderFetched = true;
        this.showDiv = "form";
      }, error => {
        this.showAlert(`No se pudo obtener el pedido.`);
        console.log("api backend error get:", error);
      });
  }

  showAlert(message) {
    this.confirmMessage = "";
    this.showPOSMessage = false;
    this.validationMessage = message;
    this.isOrderFetched = true;
    this.showDiv = "alert";
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
