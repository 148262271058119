<div *ngIf="!isOrderSent" class="wrapper">
    <div class="row">
        <div class="col-xs-12 text-center" style="margin-top: 1em;margin-bottom: 1em;">
            <img src="../../../assets/img/logo_promart.png" alt="" class="img-responsive"
                style="max-height: 85px;">
        </div>
        <div class="col-xs-12 text-left" style="margin-bottom: 2em;">
            <h3>Ingresa la dirección de entrega:</h3>
        </div>
        <div class="col-xs-12">
            <form class="form-horizontal" (ngSubmit)="onSubmit(f)" #f="ngForm">
                <div class="form-group">
                    <label for="district" class="col-sm-2 control-label">Distrito:</label>
                    <div class="col-sm-10">
                        <select 
                        id="district"
                        class="form-control" 
                        name="district"
                        ngModel
                        required>
                            <option value="" style="color:#5e5e5e !important;">Selecciona el distrito</option>
                            <option *ngFor="let district of districts" [value]="district.description">{{district.description}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="address" class="col-sm-2 control-label">Dirección:</label>
                    <div class="col-sm-10">
                        <textarea 
                        type="text"
                        id="address"
                        name="address" 
                        class="form-control" 
                        ngModel
                        required
                        placeholder="Ingresa la dirección"
                        rows="2"></textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label for="reference" class="col-sm-2 control-label">Referencia:</label>
                    <div class="col-sm-10">
                        <textarea 
                        type="text" 
                        name="reference" 
                        class="form-control" 
                        ngModel
                        required
                        placeholder="Ingresa la referencia"
                        rows="2"></textarea>
                    </div>
                </div>
                <div class="form-group" style="margin-top:3em">
                    <div class="col-sm-12 text-center">
                        <button 
                        id="btnSend"
                        [disabled]="!f.valid || !districts"
                        type="submit" 
                        class="btn" 
                        style="min-width: 200px;">Finalizar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Loading -->
<div *ngIf="isLoading">
    <div class="shadowLoading"></div>
    <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>