import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class RescheduledByPickingService {
    dataSent = new Subject<boolean>();

    constructor(private http: HttpClient) {
    }

    validateOrder(token: string, data: {}) {
        return this.http.post<any>(
            `${environment.incompletePickingApiUrl}/transaction/validate/IRMA_RESCHEDULED_BY_PICKING/${token}`,
            data,
            {
                headers: new HttpHeaders(
                    {
                        'Authorization': environment.incompletePickingApiAuth
                    }
                ),
                responseType: 'json'
            }
        );
    }

    cancelOrder(token, data: {}) {
        return this.http.post(
            `${environment.incompletePickingApiUrl}/transaction/process/IRMA_RESCHEDULED_BY_PICKING_CANCELED/${token}`,
            data,
            {
                headers: new HttpHeaders(
                    {
                        'Authorization': environment.incompletePickingApiAuth
                    }
                ),
                responseType: 'json'
            }
        );
    }
}