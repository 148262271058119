<div *ngIf="isOrderFetched && showDiv === 'form'">
    <div class="row">
        <div class="col-xs-12 
        col-sm-8 col-sm-offset-2
        col-md-6 col-md-offset-3
        col-lg-6 col-lg-offset-3 
        col-xl-4 col-xl-offset-4">

        </div>
    </div>
</div>
<div *ngIf="isOrderFetched && showDiv === 'alert'">
    <app-message [confirmMessage]="confirmMessage" [showPOSMessage]="showPOSMessage" [validationMessage]="validationMessage">
        <div class="row" style="max-width: 650px;margin: 0 auto;margin-top:20px;">
            <div class="col-sm-12" style="text-align: center">
                <strong>Dirección de retiro: </strong>{{this.address}}
            </div>
        </div>
    </app-message>
</div>

<!-- Loading -->
<div *ngIf="!isOrderFetched">
    <div class="shadowLoading"></div>
    <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>