import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { District } from './district.modal';
import { environment } from '../../../environments/environment';

@Injectable()
export class UbigeoService{    
    constructor(private http: HttpClient){
    }

    fetchDistrictsByProvince(province: string){
        let searchParams = new HttpParams();
        searchParams = searchParams.append('province', province);
        
        return this.http.get(
            `${environment.ubigeoApiUrl}/customer-care/ubigeo/v1/districts`,
            {
                headers: new HttpHeaders({ 'x-sensedia-client-id': environment.ubigeoApiClientId }),
                params: searchParams,
                responseType: 'json' 
            }
        ).pipe(
            map((responseData) => {
                const districsArray: District[] = [];

                for (const key in responseData) {    
                    if (responseData.hasOwnProperty(key) && key === "data"){
                        for (let index = 0; index < responseData["data"].length; index++) {
                            districsArray.push({...responseData["data"][index]}); 
                        }
                    }
                }

                return districsArray.sort((a, b) => a.description > b.description ? 1 : -1);
            })
        );
    }
}