import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class StoreService{
    dataSent = new Subject<boolean>();
    
    constructor(private http: HttpClient){
    }

    getAdressById(storeid: string){
        let searchParams = new HttpParams();
        searchParams = searchParams.append('code', storeid);
        
        return this.http.get(
            `${environment.storeApiUrl}/api/customer-care/v1/pvea/store`,
            {
                params: searchParams,
                responseType: 'json' 
            }
        ).pipe(
            map((responseData) => {
                let store = null;

                for (const key in responseData) {    
                    if (responseData.hasOwnProperty(key) && key === "data"){
                        if(responseData["data"]){
                            store = {...responseData["data"]}; 
                        }
                    }
                }

                return store;
            })
        );
    }

}