import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BackendService } from '../shared/backend.service';
import { Subscription } from 'rxjs';
import { StoreService } from '../shared/store.service';

@Component({
  selector: 'app-deliverytwo',
  templateUrl: './deliverytwo.component.html',
  styleUrls: ['./deliverytwo.component.css']
})
export class DeliverytwoComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  confirmMessage: string;
  showPOSMessage: boolean;
  validationMessage: string;
  token: string;
  storeId: string;
  storeName: string = "";
  isOrderFetched: boolean = false;
  showDiv: string;
  orderId: string;
  address: string;

  constructor(private route: ActivatedRoute,
    private backendService: BackendService, private storeService: StoreService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe((params: Params) => {
        this.token = params['token'];
        this.storeId = params['storeId'];
        this.getStore();
        this.getOrder();
      });

      this.subscription = this.backendService.dataSent
      .subscribe(value => {
        if(value){
          this.confirmMessage = `Un asesor de servicio se contactará contigo para coordinar 
          la fecha y hora de la entrega de tu pedido en la tienda `+this.storeName+ `.`;
          this.showPOSMessage = true;
          this.validationMessage = "";
          this.showDiv = "alert";
        }
      });
  }

  getOrder() {
    this.backendService.getOrderByToken(this.token)
      .subscribe(order => {
        if (!order) {
          this.showAlert(`No se pudo obtener el pedido.`);
          return;
        }

        if (order.tipo !== 0) {
          this.showAlert(`Tu confirmación fue enviada anteriormente.`);
          return;
        }

        this.sendData(order.orderId);

        this.orderId = order.orderId;
        this.isOrderFetched = true;
        this.showDiv = "alert";
      }, error => {
        this.showAlert(`No se pudo obtener el pedido.`);
        console.log("api backend error get:", error);
      });
  }

  getStore() {
    this.storeService.getAdressById(this.storeId)
      .subscribe(store => {
        if (!store) {
          this.showAlert(`No se pudo obtener el pedido.`);
          return;
        }

        if (store[0].address !== undefined) {
          this.address = store[0].address;
          this.storeName = store[0].nombreTienda;
          if(store[0].ubigeo.district.name){
            this.address = store[0].address +", "+store[0].ubigeo.district.name;
          }
        }else{
          return;
        }
      }, error => {
        this.showAlert(`No se pudo obtener el pedido.`);
        console.log("api store error get:", error);
      });
  }

  sendData(orderId: string){
    const data = {
      "orderId": orderId,
      "tipo": "1",
      "distrito": "",
      "direccion": "",
      "referencia": ""
    }

    this.backendService.sendData(data)
    .subscribe(resp => {
      // this.confirmMessage = `Tu cupón será activado en 48 horas. Recuerda, para usarlo debes
      // ingresar con el correo con el que realizaste tu compra.`;
      this.confirmMessage = `Un asesor de servicio se contactará contigo para coordinar la fecha y hora de la entrega de tu pedido 
       en la tienda `+this.storeName+ `.`;
      this.validationMessage = "";
      this.isOrderFetched = true;
      //this.isDisabled = false;
    }, error => {
      this.showAlert(`No se pudo procesar la solicitud.`);
      console.log("api backend error send:", error);
    });
  }

  showAlert(message) {
    this.confirmMessage = "";
    this.showPOSMessage = false;
    this.validationMessage = message;
    this.isOrderFetched = true;
    this.showDiv = "alert";
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
