import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BackendService } from '../shared/backend.service';

@Component({
  selector: 'app-pickup',
  templateUrl: './pickup.component.html',
  styleUrls: ['./pickup.component.css']
})
export class PickupComponent implements OnInit {
  confirmMessage: string;
  showPOSMessage: boolean = false;
  validationMessage: string;
  token: string;
  isOrderFetched: boolean = false;

  constructor(private route: ActivatedRoute,
    private backendService: BackendService) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe((params: Params) => {
      this.token = params['token'];
      this.getOrder();
    });
  }

  getOrder(){
    this.backendService.getOrderByToken(this.token)
    .subscribe(order => {
      if (!order) {
        this.showAlert(`No se pudo obtener el pedido.`);
        return;
      }

      if (order.tipo != "0") {
        this.showAlert(`Tu confirmación fue enviada anteriormente.`);
        return;
      }

      this.sendData(order.orderId);
    }, error => {
      this.showAlert(`No se pudo obtener el pedido.`);
      console.log("api backend error get:", error);
    });
  }

  sendData(orderId: string){
    const data = {
      "orderId": orderId,
      "tipo": "2",
      "distrito": "",
      "direccion": "",
      "referencia": ""
    }

    this.backendService.sendData(data)
    .subscribe(resp => {
      this.confirmMessage = `Apenas volvamos a operar con normalidad te enviaremos un correo
      electrónico y/o SMS para informarte la fecha en la cual podrás 
      retirar tu pedido.`;
      this.validationMessage = "";
      this.isOrderFetched = true;
    }, error => {
      this.showAlert(`No se pudo procesar la solicitud.`);
      console.log("api backend error send:", error);
    });
  }

  showAlert(message) {
    this.confirmMessage = "";
    this.validationMessage = message;
    this.isOrderFetched = true;
  }

}
