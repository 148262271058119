export const environment = {
  production: false,
  backendApiUrl: 'https://api-return-dev.cc.cloudintercorpretail.pe',
  backendApiAuth: 'Basic YWRtaW46Y3VzdG9tZXJDYXJlLjIwMjA=',
  incompletePickingApiUrl: 'https://knowledge-dev.cc.cloudintercorpretail.pe',
  incompletePickingApiAuth: 'Basic YWRtaW46Y3VzdG9tZXJDYXJlLjIwMjA=',
  ubigeoApiUrl: 'https://api.irdigital.pe/dev',
  ubigeoApiClientId: 'f633e94e-4b20-3872-811a-13c33357ee9f',
  ubigeoApiLimaProvinceCode: '1501',
  storeApiUrl: 'https://store-dev.cc.cloudintercorpretail.pe',
  userRescheduledByPickingApi: 'CC_SYSTEM'
};
