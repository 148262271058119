<div *ngIf="!isDisabled">
    <div *ngIf="isOrderFetched">
        <app-message 
        [confirmMessage]="confirmMessage" 
        [showPOSMessage]="showPOSMessage"
        [validationMessage]="validationMessage">
            <div class="row" style="max-width: 650px;margin: 0 auto;margin-top:20px;">
                <div class="col-sm-12" style="padding-left: 30px;">
                    <strong>Ten en cuenta las siguientes condiciones para utilizar tu cupón:</strong>
                </div>
                <div class="col-sm-12" style="margin-top:20px;">
                    <ul>
                        <li style="padding:5px 0px;">Solo será válido durante el mes de Junio.</li>
                        <li style="padding:5px 0px;">Podrá ser utilizado una sola vez y el monto de compra deberá ser igual
                            o mayor al monto del cupón.</li>
                        <li style="padding:5px 0px;">No aplica a canje parcial.</li>
                        <li style="padding:5px 0px;">Solo aplica a compras en la tienda virtual de Promart.</li>
                        <li style="padding:5px 0px;">Aplica a todas las categorías excepto electrohogar, construcción, máquinas
                            para jardinería, cables y alambres eléctricos.</li>
                        <li style="padding:5px 0px;">Aplica para todos los productos vendidos y despachados por Promart. No
                            aplica para productos vendidos por otro proveedores como por ejemplo:
                            Plaza Vea y Oechsle.</li>
                    </ul>
                </div>
            </div>
        </app-message>
    </div>    
    
    <!-- Loading -->
    <div *ngIf="!isOrderFetched">
        <div class="shadowLoading"></div>
        <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
        </div>
    </div>
</div>

<div id="divStatic" *ngIf="isDisabled && isOrderFetched">
    <div class="row">
        <div class="col-xs-12 
        col-sm-8 col-sm-offset-2
        col-md-6 col-md-offset-3
        col-lg-6 col-lg-offset-3 
        col-xl-4 col-xl-offset-4">
        <div class="wrapper">
            <div class="row">
                <div class="col-xs-12 text-center" style="margin-bottom: 0em;">
                    <img src="../../assets/img/logo_promart.png" 
                    alt=""
                    class="img-responsive"
                    style="max-height: 127px;">
                </div>
                <div class="col-xs-12 text-center" 
                style="margin-bottom: 1.5em;">
                    <h3 style="margin-top:0px;">Esta opción ya no se <br/> encuentra habilitada</h3>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>