import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class IncompletePickingService {
    dataSent = new Subject<boolean>();

    constructor(private http: HttpClient) {
    }

    getOrderByToken(token: string) {
        return this.http.get<any>(
            `${environment.incompletePickingApiUrl}/transaction/validate/IRMA_DAD_PROMART_CONFIRMATION/${token}`,
            {
                headers: new HttpHeaders(
                    {
                        'Authorization': environment.incompletePickingApiAuth
                    }
                ),
                responseType: 'json'
            }
        );
    }

    confirmOrder(token, data: {}) {
        return this.http.post(
            `${environment.incompletePickingApiUrl}/transaction/IRMA_DAD_PROMART_CONFIRMATION/${token}`,
            data,
            {
                headers: new HttpHeaders(
                    {
                        'Authorization': environment.incompletePickingApiAuth
                    }
                ),
                responseType: 'json'
            }
        );
    }

    cancelOrder(token, data: {}) {
        return this.http.post(
            `${environment.incompletePickingApiUrl}/transaction/IRMA_DAD_PROMART_CANCELED/${token}`,
            data,
            {
                headers: new HttpHeaders(
                    {
                        'Authorization': environment.incompletePickingApiAuth
                    }
                ),
                responseType: 'json'
            }
        );
    }
}