import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class BackendService{
    dataSent = new Subject<boolean>();
    
    constructor(private http: HttpClient){
    }

    getOrderByToken(token: string){
        let searchParams = new HttpParams();
        searchParams = searchParams.append('token', token);
        
        return this.http.get(
            `${environment.backendApiUrl}/api/customer-care/v1/promart/pedido`,
            {
                headers: new HttpHeaders(
                    { 
                        'Authorization': environment.backendApiAuth
                    }
                ),
                params: searchParams,
                responseType: 'json' 
            }
        ).pipe(
            map((responseData) => {
                let order = null;

                for (const key in responseData) {    
                    if (responseData.hasOwnProperty(key) && key === "data"){
                        if(responseData["data"]){
                            order = {...responseData["data"]}; 
                        }
                    }
                }

                return order;
            })
        );
    }

    sendData(data: {}){
        return this.http.post(
            `${environment.backendApiUrl}/api/customer-care/v1/promart/pedido/save`,
            data,
            {
                headers: new HttpHeaders(
                    { 
                        'Authorization': environment.backendApiAuth
                    }
                ),
                responseType: 'json' 
            }
        );
    }
}